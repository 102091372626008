import { Injectable, Inject, Optional, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Observable, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';
import { API_BASE_URL } from './service-proxies';


@Injectable()
export class DocumentiDownloadServiceProxy {
    private http: HttpClient;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(
        @Inject(HttpClient) http: HttpClient,
        @Optional() @Inject(API_BASE_URL) baseUrl?: string
    ) {
        this.http = http;
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "";
    }

    downloadDocumento(fileName: string | undefined): Observable<Blob> {
        let url_ = this.baseUrl + "/api/services/app/Documenti/DownloadDocumento?";
        if (fileName === null)
            throw new Error("The parameter 'fileName' cannot be null.");
        else if (fileName !== undefined)
            url_ += "fileName=" + encodeURIComponent("" + fileName) + "&";
        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({})
        };

        return this.http.request("get", url_, options_).pipe(
            _observableMergeMap((response_ : any) => {
                return this.processDownloadDocumento(response_);
            })
        );
    }

    downloadAllDocumento(fileName: string | undefined): Observable<Blob> {
        let url_ = this.baseUrl + "/api/services/app/Documenti/DownloadAllDocumento?";
        if (fileName === null)
            throw new Error("The parameter 'fileName' cannot be null.");
        else if (fileName !== undefined)
            url_ += "praticaId=" + encodeURIComponent("" + fileName) + "&";
        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({})
        };

        return this.http.request("post", url_, options_).pipe(
            _observableMergeMap((response_ : any) => {
                return this.processDownloadDocumento(response_);
            })
        );
    }

    protected processDownloadDocumento(response: HttpResponseBase): Observable<Blob> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return _observableOf(responseBlob);
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(_observableMergeMap((_responseText: string) => {
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf(<Blob>null);
    }
}

function blobToText(blob: Blob): Observable<string> {
    return new Observable<string>((observer) => {
        if (!blob) {
            observer.next("");
            observer.complete();
        } else {
            const reader = new FileReader();
            reader.onload = event => {
                observer.next((<any>event.target).result);
                observer.complete();
            };
            reader.readAsText(blob);
        }
    });
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }): Observable<any> {
    return _observableThrow(new Error(message));
}
