import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '@shared/services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    reqCount: number = 0;

    constructor(private loaderService: LoaderService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.headers.has('Skip-Interceptor')) {
            const headers = request.headers.delete('Skip-Interceptor');
            const clonedReq = request.clone({ headers });
            return next.handle(clonedReq).pipe(
                finalize(() => this.checkReqCount()),
                catchError((err: any) => {
                    this.checkReqCount();
                    this.loaderService.activate();
                    return throwError(err);
                })
            );
        }else{
            if (!this.reqCount) {
                this.startLoading();
            }
            if (this.loaderService.active) {
                this.reqCount++;
            }
            return next.handle(request).pipe(
                finalize(() => this.checkReqCount()),
                catchError((err: any) => {
                    this.checkReqCount();
                    this.loaderService.activate();
                    return throwError(err);
                })
            );
        }
    }

    checkReqCount() {
        if (this.reqCount) {
            this.reqCount--;
        }
        if (this.reqCount <= 0) {
            this.stopLoading();
        }
    }

    startLoading() {
        this.loaderService.show();
    }

    stopLoading() {
        this.loaderService.hide();
    }
}
