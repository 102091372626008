import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpHttpInterceptor } from 'abp-ng2-module';

import * as ApiServiceProxies from './service-proxies';
import { LoaderInterceptor } from '@shared/interceptors/loader.interceptor';
import * as DownloadDocumentiServiceProxies from './documenti-download';

@NgModule({
    providers: [
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.ConfigurationServiceProxy,
        ApiServiceProxies.ClientiServiceProxy,
        ApiServiceProxies.ModelliPraticaServiceProxy,
        ApiServiceProxies.RetiAssociativeServiceProxy,
        ApiServiceProxies.PraticheServiceProxy,
        ApiServiceProxies.RegioniServiceProxy,
        ApiServiceProxies.AreaTerritorialiServiceProxy,
        ApiServiceProxies.ModelliPraticaItemServiceProxy,
        ApiServiceProxies.RetiAssociativeUserServiceProxy,
        ApiServiceProxies.PraticaStatiServiceProxy,
        ApiServiceProxies.DocumentiServiceProxy,
        ApiServiceProxies.PraticaItemsServiceProxy,
        ApiServiceProxies.CronologieServiceProxy,
        ApiServiceProxies.NotificheServiceProxy,
        DownloadDocumentiServiceProxies.DocumentiDownloadServiceProxy,
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
    ],
})
export class ServiceProxyModule {}
