import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { AppComponentBase } from '@shared/app-component-base';

@Component({
    selector: 'abp-modal-footer',
    templateUrl: './abp-modal-footer.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbpModalFooterComponent extends AppComponentBase {
    @Input() cancelLabel = this.l('Annulla');
    @Input() cancelDisabled: boolean;
    @Input() saveLabel = this.l('Salva');
    @Input() saveDisabled: boolean;

    @Output() onCancelClick = new EventEmitter<number>();

    constructor(injector: Injector) {
        super(injector);
    }
}
